/*
 * @Author: Json Weng dinglong.weng@travel-easy.com
 * @Date: 2022-05-31 22:03:10
 * @LastEditors: Json Weng dinglong.weng@travel-easy.com
 * @LastEditTime: 2024-04-27 23:04:21
 * @FilePath: \magicbean-saas-base\src\core\api\role.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getRoleList(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/role/index",
      params
    );
  },
  addRole(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/role/store",
      params
    );
  },
  updateRole(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/role/update",
      params
    );
  },
  deleteRole(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/role/delete",
      params
    );
  },
  getRoleInfo(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/role/show",
      params
    );
  },
  getRolePermission(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/role/get-permission",
      params
    );
  },
  saveRolePermission(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/role/save-permission",
      params
    );
  },
  getDataSourceRbacPermits() {
    return ApiService.post(Config.baseApiUrl + "/api/data-source/rbac/permits");
  },
  getDataSourceRbacRoles() {
    return ApiService.post(Config.baseApiUrl + "/api/data-source/rbac/roles");
  },
  getDataSourceEntity(params) {
    return ApiService.post(
      Config.settlementApiUrl + "/api/settlement-entity/options",
      params
    );
  },
  getUserPermissionConfig(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/permission-config",
      params
    );
  },
  saveUserPermissionConfig(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/save-permission-config",
      params
    );
  },
};
