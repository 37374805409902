/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-06-26 14:36:01
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-28 16:08:35
 * @FilePath: /base-frontend/src/directives/permission.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Directive } from "vue";
import store from "@/store";

const checkPermission = (el, binding) => {
  const { value } = binding;
  const actionPermits = store.getters.routeActionPermits;
  // console.log(actionPermits);
  if (value && value.auth instanceof Array) {
    let hasPermission = false;
    if (value.mode && value.mode == "or") {
      hasPermission = value.auth.some((item) => {
        return actionPermits.includes(item);
      });
    } else if (value.mode && value.mode == "and") {
      hasPermission = value.auth.every((item) => {
        return actionPermits.includes(item);
      });
    } else {
      hasPermission = value.auth.some((item) => {
        return actionPermits.includes(item);
      });
    }
    // console.log("Has permission: " + hasPermission);
    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  } else {
    throw new Error(
      "Need permission! Like v-auth={auth: ['view','add'], mode: 'or'}"
    );
  }
};

export const auth: Directive = {
  mounted(el, binding) {
    checkPermission(el, binding);
  },
  updated(el, binding) {
    checkPermission(el, binding);
  },
};
