import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getAppList(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/app/index",
      params
    );
  },
  addApp(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/app/store",
      params
    );
  },
  deleteApp(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/app/delete",
      params
    );
  },
  updateApp(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/app/update",
      params
    );
  },
  getAppInfo(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/app/show",
      params
    );
  },
};
