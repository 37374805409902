import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ApiAuth } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
export interface User {
  id: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  language: string;
  advanced_manage: number;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
  token: string | null;
}
@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  token = JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
  }

  @Mutation
  [Mutations.SET_TOKEN](token) {
    JwtService.saveToken(token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiAuth.login(credentials)
        .then(({ data }) => {
          if (data.code == 0) {
            this.context.commit(Mutations.SET_AUTH, data.data.profile);
            this.context.commit(Mutations.SET_TOKEN, data.data.token);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, [data.sub_msg]);
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
          this.context.commit(Mutations.SET_ERROR, error);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    ApiService.setHeader();
    ApiAuth.logout()
      .then(({ data }) => {
        if (data.code == 0) {
          this.context.commit(Mutations.PURGE_AUTH);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Action
  [Actions.REFRESH_TOKEN]() {
    ApiAuth.refreshToken()
      .then(({ data }) => {
        if (data.code == 0) {
          console.log("TOKEN REFRESHED");
          this.context.commit(Mutations.SET_TOKEN, data.data.token);
          location.reload();
        } else {
          this.context.commit(Mutations.PURGE_AUTH);
          router.replace({ path: "/sign-in" });
        }
      })
      .catch((error) => {
        console.log(error);
        this.context.commit(Mutations.PURGE_AUTH);
        router.replace({ path: "/sign-in" });
      });
  }

  @Action
  [Actions.VERIFY_AUTH](callback) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiAuth.verify()
        .then(({ data }) => {
          if (data.code == 0) {
            this.context.commit(Mutations.SET_AUTH, data.data);
            JwtService.setLang(data.data.language);
            callback();
          } else if (data.code == 30002) {
            if (data.sub_code.endsWith("TOKEN-EXPIRED")) {
              console.log("TOKEN EXPIRED");
              if (
                document.getElementsByClassName("swal2-container").length === 0
              ) {
                const { t } = useI18n();
                Swal.fire({
                  title: t("common.loginExpired"),
                  icon: "info",
                  buttonsStyling: false,
                  showCancelButton: true,
                  confirmButtonText: t("common.keepLogin"),
                  cancelButtonText: t("common.logout"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.context.dispatch(Actions.REFRESH_TOKEN);
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    this.context.dispatch(Actions.LOGOUT);
                    router.replace({ path: "/sign-in" });
                  }
                });
              }
            } else {
              console.log("TOKEN INVALID");
              if (
                document.getElementsByClassName("swal2-container").length === 0
              ) {
                const { t } = useI18n();
                Swal.fire({
                  title: t("common.loginTokenInvalid"),
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: t("common.login"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.context.commit(Mutations.PURGE_AUTH);
                    router.replace({ path: "/sign-in" });
                  }
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.context.commit(Mutations.PURGE_AUTH);
          router.replace({ path: "/sign-in" });
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CHECK_TOKEN](data) {
    if (JwtService.getToken()) {
      if (data.code == 30002) {
        if (data.sub_code.endsWith("TOKEN-EXPIRED")) {
          if (document.getElementsByClassName("swal2-container").length === 0) {
            const { t } = useI18n();
            Swal.fire({
              title: t("common.loginExpired"),
              icon: "info",
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: t("common.keepLogin"),
              cancelButtonText: t("common.logout"),
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.context.dispatch(Actions.REFRESH_TOKEN);
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.context.dispatch(Actions.LOGOUT);
                router.replace({ path: "/sign-in" });
              }
            });
          }
        } else {
          if (document.getElementsByClassName("swal2-container").length === 0) {
            const { t } = useI18n();
            Swal.fire({
              title: t("common.loginTokenInvalid"),
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: t("common.login"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                this.context.commit(Mutations.PURGE_AUTH);
                router.replace({ path: "/sign-in" });
              }
            });
          }
        }
      }
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
      router.replace({ path: "/sign-in" });
    }
  }
}
