/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-01-30 08:46:50
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-06-30 13:11:27
 * @FilePath: /base-frontend/src/core/api/auth.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  login(params) {
    return ApiService.post(Config.baseApiUrl + "/api/authorize/login", params);
  },

  verify() {
    return ApiService.post(Config.baseApiUrl + "/api/authorize/me", {});
  },

  refreshToken() {
    return ApiService.post(Config.baseApiUrl + "/api/authorize/refresh", {});
  },

  logout() {
    return ApiService.post(Config.baseApiUrl + "/api/authorize/logout", {});
  },

  addUser(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/store", params);
  },

  updateUser(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/update", params);
  },

  deleteUser(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/delete", params);
  },

  getUserInfo(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/show", params);
  },

  getUserList(params) {
    return ApiService.post(Config.baseApiUrl + "/api/user/index", params);
  },
  getUserCustomerList(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/user-customer-index",
      params
    );
  },
  searchUserCustomer(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/user-customer-search",
      params
    );
  },
  addUserCustomer(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/save-user-customer",
      params
    );
  },
  deleteUserCustomer(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/deleted-user-customer",
      params
    );
  },
  getUserMerchantList(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/user-merchant-index",
      params
    );
  },
  searchUserMerchant(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/user-merchant-search",
      params
    );
  },
  addUserMerchant(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/save-user-merchant",
      params
    );
  },
  deleteUserMerchant(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/user/deleted-user-merchant",
      params
    );
  },
};
