import menu from "magicbean-saas-common/src/core/language/en/menu.json";
import common from "./common.json";
import user from "./user.json";
import permissions from "./permissions.json";

export default {
  menu,
  common,
  user,
  permissions,
};
