import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
  getMenuList(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/menu/index",
      params
    );
  },
  addMenu(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/menu/store",
      params
    );
  },
  deleteMenu(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/menu/delete",
      params
    );
  },
  updateMenu(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/menu/update",
      params
    );
  },
  getMenuInfo(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/foundation/menu/show",
      params
    );
  },
  getMenuSourceData(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/menu-data/option",
      params
    );
  },
  getAppSourceData(params) {
    return ApiService.post(
      Config.baseApiUrl + "/api/data-source/app-data/option",
      params
    );
  },
};
