
import { computed, defineComponent } from "vue";

import store from "./store";
import zhCN from "element-plus/es/locale/lang/zh-cn";
import enUS from "element-plus/es/locale/lang/en";
import deDE from "element-plus/es/locale/lang/de";
export default defineComponent({
  name: "app",
  setup() {
    const locale = computed(() => {
      const lang = store.getters.currentUser?.language;
      switch (lang) {
        case "zh_CN":
          return zhCN;
        case "en_US":
          return enUS;
        case "de_DE":
          return deDE;
        default:
          return enUS;
      }
    });

    return {
      locale,
    };
  },
});
